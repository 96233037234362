export class CancelablePromise<T> extends Promise<T> {
    private _controller: AbortController;

    constructor(
        executor: (
            resolve: (value?: T | PromiseLike<T>) => void,
            reject: (reason?: any) => void,
            signal: AbortSignal
        ) => void, abortController: AbortController = new AbortController()) {
        super((res, rej) => executor(res, rej, abortController.signal));

        this._controller = abortController;
    }

    abort() {
        this._controller.abort();
    }

    /**
     * Creates a CancelablePromise that is resolved with an array of results when all of the provided Promises
     * resolve, or rejected when any Promise is rejected.
     * @param values An array of Promises.
     * @returns A new CancelablePromise.
     */
    public static allCancelable<T>(values: (T | PromiseLike<T>)[]): CancelablePromise<T[]> {
        return new CancelablePromise<T[]>((resolve, reject, signal) => {
            Promise.all(values).then(resolve).catch(reject);

            signal.addEventListener('abort', () => {
                values.forEach(p => {
                    if (p instanceof CancelablePromise) {
                        p.abort()
                    }
                });
            });
        });
    }
}