import React, { useState } from 'react';
import "./BarChart.scss";
import { AsNumber } from './helpers/formatters/AsNumber';
import { Icon } from './one-ui/atoms/Icon';

export type ChartData = { x: string, y: number }[];

export interface BarChartProps {
    label: string;
    data: ChartData;
    limit?: number;
}

export const BarChart: React.FunctionComponent<BarChartProps> = (props) => {
    const [showLimited, setShowLimited] = useState(true);
    const limit = props.limit || 0;
    const isLimited = props.limit && (props.limit < props.data.length);
    const data = isLimited && showLimited ? [
        ...props.data.slice(0, limit - 1),
        { x: '[Other]', y: props.data.slice(limit - 1).map(d => d.y).reduce((a, b) => a + b) }
    ] : [...props.data];
    const max = Math.max(...data.map(d => d.y));

    return (
        <div className="ch-bar-chart">
            <div className="ch-bar-chart-label">
                {props.label}
            </div>
            <div className="ch-bars">
                {data.map(bar => (
                    <div className="ch-bar-wrapper" key={bar.x}>
                        <div className="ch-bar-label" title={`${bar.x}: ${bar.y}`}>{bar.x}</div>
                        <div className="ch-bar-value">
                            <AsNumber>{bar.y}</AsNumber>
                        </div>
                        <div className="ch-bar" style={{ width: `${bar.y * 100 / max}%` }}></div>
                    </div>
                ))}
            </div>
            {isLimited ? <div className="ch-show-limited" onClick={() => setShowLimited(!showLimited)}>
                {showLimited
                    ? <><Icon name="chevron-down" /> More</>
                    : <><Icon name="chevron-up" /> Less</>}
            </div> : null}
        </div>
    );
};
