
import React from 'react';
import './Nav.scss';
import { NavLink } from 'react-router-dom';

export const Nav: React.FunctionComponent = ({ children }) => (
    <nav className="one-nav">
        {children}
    </nav>
);

export interface NavItemProps {
    to: string;
    title: string;
}

export const NavItem: React.FunctionComponent<NavItemProps> = ({ to, title }) => (
    <NavLink to={`/${to}`} className="one-nav-item" activeClassName="one-nav-item-active">
        <span className="one-nav-item-title">
            {title}
        </span>
    </NavLink >
);