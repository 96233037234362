/**
 * @see https://material-ui.com/getting-started/installation/
 */

import React, { useState, useEffect } from 'react';
import './components/one-ui/one.css';
import './App.scss';
// import { Target } from './api/expenses';
// import { Account } from './api/accounts';
// import { ChartData } from './components/BarChart';
import { Loader } from './components/one-ui/atoms/Loader';
import { DashboardPage } from './pages/DashboardPage';
import { useLoadingState } from './api/hooks';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Nav, NavItem } from './components/one-ui/molecules/Nav';
import { TodayPage } from './pages/TodayPage';
import { Header } from './components/one-ui/atoms/Header';
import { session as currentSession, Session } from './api/users';
import { SessionContext } from './context/SessionContext';

// export interface AppState {
//     accounts: Account[];
//     targets: Target[];
//     chartYearlyExpenses: ChartData;
//     loading: null | number;
// }

interface AppPage {
    name?: string;
    title: string;
    role: number;
    icon: string;
    component: React.FunctionComponent;
}

const appPages: { [name: string]: AppPage } = {
    today: {
        title: 'Այսօր',
        role: 0,
        icon: 'calendar',
        component: TodayPage
    },
    summary: {
        title: "\u0531\u0574\u0583\u0578\u0583\u0578\u0582\u0574",
        role: 0,
        icon: "tachometer",
        component: DashboardPage
    },
    me: {
        title: "\u0535\u057d",
        role: 0,
        icon: "user",
        component: DashboardPage
    },
    expenses: {
        title: "\u053e\u0561\u056d\u057d\u0565\u0580",
        role: 16,
        icon: "money",
        component: DashboardPage
    },
    users: {
        title: "\u0531\u0576\u0564\u0561\u0574\u0576\u0565\u0580",
        role: 16,
        icon: "users",
        component: DashboardPage
    }
};

const appPagesList = Object.keys(appPages).map(p => Object.assign({ name: p }, appPages[p]));

// const dates: { threeDays: string[] } = {
//     threeDays: [
//         '2019-08-08',
//         '2019-08-07',
//         '2019-08-06',
//         '2019-08-05',
//         '2019-08-04',
//         '2019-08-03',
//         '2019-08-02',
//         '2019-08-01'
//     ],
// };

/*
        targets().then(data => this.setState({ targets: data.list }));

        accounts().then(data => this.setState({ accounts: data.list }));

        expensesByYear(2019).then(data => this.setState({
            chartYearlyExpenses: data.list.map(d => ({ x: d.target, y: parseFloat(d.sum) }))
        }));

        Promise.all(this.dates.threeDays.map(date => expensesByDate(date))).then(data => {
            //TODO: use result
        });
*/

export const App: React.FunctionComponent = () => {
    const loading = useLoadingState();
    const [session, setSession] = useState<Session | undefined>(undefined);

    useEffect(() => {
        currentSession().then(data => {
            setSession(data);
        });
    }, []);

    return (
        <SessionContext.Provider value={session}>
            <Router>
                <div className="App">
                    <Switch>
                        {appPagesList.map(page => (
                            <Route key={page.name} path={`/${page.name}`}>
                                <Header title={page.title} /*description={session && `Բարև Ձեզ ${session.name}`}*/ />
                            </Route>
                        ))}
                    </Switch>
                    <section>
                        <Switch>
                            <Redirect from="/" to={`/${appPagesList[0].name}`} exact />

                            {appPagesList.map(page => (
                                <Route key={page.name} path={`/${page.name}`} component={page.component} />
                            ))}
                        </Switch>
                    </section>

                    <Nav>
                        {appPagesList.map(page => (
                            <NavItem key={page.name} to={page.name} title={page.title} />
                        ))}
                    </Nav>

                    {/* <button className="p-add-expenses float-button" title="Ավելացնել">
                        <i className="fa fa-plus"></i>
                    </button> */}

                    {loading !== null ? <Loader progress={loading} /> : null}
                </div>
            </Router>
        </SessionContext.Provider>
    );
};
