export type ObservableCallback<T> = (newValue: T, oldValue: T, observable: ObservableBase<T>) => void;

abstract class ObservableBase<T> extends Function {
    protected _subscribers: ObservableCallback<T>[];
    protected abstract _oldValue: T;

    constructor() {
        super();

        this._subscribers = [];
    }

    public get value() {
        if (ObservableBase.formulaSubscriptionMode) {
            this.subscribe(ObservableBase.formulaSubscriptionCallback);
        }

        return this._getValue();
    }

    public set value(newValue: T) {
        this._setValue(newValue);
    }

    protected abstract _getValue(): T;

    protected _setValue(newValue: T): void {
        throw new Error('Value can not be changed.');
    }

    subscribe(callback: ObservableCallback<T>) {
        if (this._subscribers.indexOf(callback) === -1) {
            this._subscribers.push(callback);
        }

        return this.unsubscribe.bind(this, callback);
    }

    unsubscribe(callback: ObservableCallback<T>) {
        let index = this._subscribers.indexOf(callback);

        if (index !== -1) {
            this._subscribers.splice(index, 1);
        }
    }

    protected notifySubscribers(newValue: T) {
        try {
            for (const callback of this._subscribers) {
                try {
                    callback(newValue, this._oldValue, this);
                } catch (error) {
                    console.error('Observable.notifySubscribers', error);
                }
            }
        } finally {
            this._oldValue = newValue;
        }
    }

    private static formulaSubscriptionMode = false;
    private static formulaSubscriptionCallback: () => void = () => void (0);
    private static formulaSubscriptionUnsubscribers: (() => void)[];

    public static beginSubscribeToFormula(callback: () => void) {
        ObservableBase.formulaSubscriptionMode = true;
        ObservableBase.formulaSubscriptionUnsubscribers = [];
        ObservableBase.formulaSubscriptionCallback = callback;
    }

    public static endSubscribeToFormula(): () => void {
        ObservableBase.formulaSubscriptionMode = false;

        return (
            us => (
                () => us.forEach(u => u())
            )
        )([...ObservableBase.formulaSubscriptionUnsubscribers]);
    }
}

export class Observable<T> extends ObservableBase<T> {
    protected _oldValue: T;
    private _updating = false;
    private _value: T;

    constructor(
        private defaultValue: T
    ) {
        super();

        this._oldValue = defaultValue;
        this._value = defaultValue;
    }

    protected _getValue() {
        return this._value;
    }

    protected _setValue(newValue: T) {
        if (newValue === this._oldValue) {
            return;
        }

        if (this._updating) {
            throw new Error('Value can not be changed during another change.');
        }

        this._updating = true;

        this._value = newValue;

        try {
            this.notifySubscribers(newValue);
        } finally {
            this._updating = false;
        }
    }
}

export class Computed<T> extends ObservableBase<T> {
    protected _oldValue: T;
    private _unsubscriber: () => void;
    private _value: T;

    constructor(private _formula: () => T) {
        super();

        let _updating = false;
        let _immediate = 0;

        ObservableBase.beginSubscribeToFormula(() => {
            if (_updating) {
                throw new Error('Value can not be changed during another change.');
            }

            _updating = true;

            const newValue = this._calculate();

            _updating = false;

            if (_immediate) {
                clearTimeout(_immediate);

                _immediate = 0;
            }

            if (newValue !== this._oldValue) {
                _immediate = setTimeout(() => {
                    _updating = true;
            
                    try {
                        this.notifySubscribers(newValue);
                    } finally {
                        _updating = false;
                    }
                });
            }
        });

        this._oldValue = this._value = this._calculate()

        this._unsubscriber = ObservableBase.endSubscribeToFormula();
    }

    private _calculating = false;

    private _calculate() {
        try {
            if (this._calculating) {
                throw new Error('Value can not be changed during another change.');
            }

            this._calculating = true;

            return this._formula();
        } finally {
            this._calculating = false;
        }
    }

    protected _getValue(): T {
        if (this._calculating) {
            throw new Error('Formula depended to the itself.');
        }

        return this._value;
    }
}