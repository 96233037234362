import React from 'react';
import './Grid.scss';
import { Table, TableHead, TableBody, TableFoot, TableHeadCell, TableRow, TableCell, TableCellTextAlign } from '../atoms/Table';
import { Container, ContainerScroll } from '../atoms/Container';

type Row = { [field: string]: string | number | boolean | null | undefined };

export interface GridColumn<T extends Row = Row> {
    name: string;
    field?: keyof T;
    title?: string | React.ReactNode;
    span?: number;
    render?: (row: T, index: number) => (React.ReactNode | string);
    align?: TableCellTextAlign;
}

export interface GridProps<T extends Row = Row> {
    className?: string;
    columns?: GridColumn<T>[];
    data?: T[];
    scroll?: ContainerScroll;
}

export const Grid: React.FunctionComponent<GridProps> = ({
    className = '',
    columns = [],
    data = [],
    scroll = 'horizontal'
}) => {
    if (columns.length === 0) {
        if (data.length === 0) {
            return <div>{Error('Columns for Grid not specified.')}</div>;
        }

        columns = Object.keys(data[0]).map(name => ({ name, field: name }));
    }

    return (
        <Container className={`one-grid ${className}`} scroll={scroll}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map(({ name, title = null }) => (
                            <TableHeadCell key={name}>{title || name}</TableHeadCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                            {columns.map(({ name, render = null, field = null, align = 'left' }) => (
                                <TableCell key={name} align={align}>
                                    {render
                                        ? render(row, index)
                                        : row[field || name]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFoot></TableFoot>
            </Table>
        </Container>
    );
};
