import { getWeekdayName } from "./util";

export class ReportingDate {
    y?: number;
    m?: number;
    w?: number;
    d?: number;

    constructor();
    constructor(data: Date);
    constructor(date: string);
    constructor(date: number);

    constructor() {
        var fromDateString = function (that: ReportingDate, text: string) {
            return fromDate(that, new Date(text));
        }, fromMyDateString = function (that: ReportingDate, text: string) {
            if ((text = '' + text) === '') {
                return null;
            }

            var d, w, m, y, parts;

            switch (text.length) {
                case 10:
                    parts = text.split('-');
                    if (parts.length === 3 && ((y = parseInt(parts[0])) && y >= 1900 && y <= 2100) && ((m = parseInt(parts[1])) && m >= 1 && m <= 12) && ((d = parseInt(parts[2])) && d >= 1 && d <= 31)) {
                        // No Action
                    } else {
                        return null;
                    }
                    break;
                case 4:
                    if ((y = parseInt(text)) && y > 1900 && y < 2100) {
                        // No Action
                    } else {
                        return null;
                    }
                    break;
                case 7:
                    if ((parts = text.split('-')).length === 2)
                        if (((y = parseInt(parts[0])) && y >= 1900 && y <= 2100) && ((m = parseInt(parts[1])) && m >= 1 && m <= 12)) {
                            // No Action
                        } else {
                            return null;
                        }
                    else if ((parts = text.split('/')).length === 2) {
                        if (((y = parseInt(parts[0])) && y >= 1900 && y <= 2100) && ((w = parseInt(parts[1])) && w >= 1 && w <= 53)) {
                            // No Action
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                    break;
                default:
                    return null;
            }

            that.y = y;
            that.m = m;
            that.w = w;
            that.d = d;

            return that;
        }, fromDate = function (that: ReportingDate, date: Date) {
            that.y = date.getFullYear();
            that.m = date.getMonth() + 1;
            that.w = undefined;
            that.d = date.getDate();

            return that;
        };

        if (arguments.length === 0) {
            return fromDate(this, new Date());
        }

        var a = arguments[0];

        if (a instanceof Date) {
            return fromDate(this, a);
        }

        //if (a instanceof ReportingDate) {
        //    return fromDate(this, a);
        //}

        if (typeof a === 'number') {
            var d = new Date();
            d.setDate(d.getDate() + a);
            return fromDate(this, d);
        }

        if (typeof a === 'string') {
            var v;

            if ((v = fromMyDateString(this, a))) {
                return v;
            }

            try {
                return fromDateString(this, a);
            } catch (error) { }
        }

        throw new Error('Invalid Date');
    }

    get level() {
        return this.d ? 'day' : (this.w ? 'week' : (this.m ? 'month' : 'year'));
    }

    toString() {
        switch (this.level) {
            case 'year':
                return this.y + '';
            case 'month':
                return this.y + '-' + ('0' + this.m).slice(-2);
            case 'week':
                return this.y + '/' + ('0' + this.w).slice(-2);
            case 'day':
                return this.y + '-' + ('0' + this.m).slice(-2) + '-' + ('0' + this.d).slice(-2);
            default:
                throw new Error('Invalid value');
        }
    }

    toDate() {
        if (this.y && this.m && this.d) {
            return new Date(this.y, this.m - 1, this.d);
        }

        throw new Error('Invalid operation');
    }

    valueOf() {
        return this.toDate();
    }

    longString() {
        switch (this.level) {
            case 'year':
                return this.toString();
            case 'month':
                return this.toString() + ', ' + ['?', 'Հնվ', 'Փտր', 'Մրտ', 'Ապր', 'Մյս', 'Հնս', 'Հլս', 'Օգս', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'][this.m || 0];
            case 'week':
                return this.toString();
            case 'day':
                return this.toString() + ', ' + getWeekdayName(this);
            default:
                throw new Error('Invalid value');
        }
    }

    addDays(days: number) {
        if (this.level !== 'day') {
            throw new Error('addDays allowed only for level=days.');
        }

        const date = this.toDate();

        date.setDate(date.getDate() + days);

        return new ReportingDate(date);
    }

    public static range(start: DateRangeOffset, end: DateRangeOffset = 'today') {
        const a = ReportingDate.offsetToDate(start);
        const b = ReportingDate.offsetToDate(end);
        const increment = b > a;
        const result = [];

        for (let date = a; (increment ? date <= b : date >= b); date = date.addDays(increment ? 1 : -1)) {
            result.push(date);
        }

        return result;
    }

    public static offsetToDate(offset: DateRangeOffset) {
        switch (typeof (offset)) {
            case 'number':
                return new ReportingDate(offset);
            case 'string':
                switch (offset) {
                    case 'today':
                        return new ReportingDate();
                    default:
                        return new ReportingDate(offset);
                }
        }
    }
}

export type DateRangeOffset = 'today' | number | string;