import { ReportingDate } from "./ReportingDate";

export function getTypeName(type: string) {
    return ['', 'Փոխանցում', 'Ծախս', 'Մուտք'][Number(type)];
}

export function getTypeIcon(type: string) {
    return ['', 'fa fa-exchange', 'fa fa-sign-out', 'fa fa-sign-in'][Number(type)];
}

export function getWeekdayName(day: ReportingDate | string | Date | number) {
    if (day instanceof ReportingDate) {
        day = String(day);
    }

    if (typeof day === 'string') {
        day = new Date(day);
    }

    if (day instanceof Date) {
        day = day.getDay();
    }

    return ['Կրկ', 'Երկ', 'Երք', 'Չրք', 'Հնգ', 'Ուրբ', 'Շբթ'][day];
}

// export function daysInMonth(year: number, month: number) {
//     return ((new Date(year, month + 1, 1)) - (new Date(year, month, 1))) / (1000 * 60 * 60 * 24);
// }

export function monthName(month: number) {
    switch (month) {
        case 1:
            return '';
        case 2:
            return '';
        case 3:
            return '';
        case 4:
            return '';
        case 5:
            return '';
        case 6:
            return '';
        case 7:
            return '';
        case 8:
            return '';
        case 9:
            return '';
        case 10:
            return '';
        case 11:
            return '';
        case 12:
            return '';
        default:
            throw new Error('Invalid month index');
    }
}

export function monthNameShort(month: number) {
    switch (month) {
        case 1:
            return 'Հնվ';
        case 2:
            return 'Փտվ';
        case 3:
            return 'Մրտ';
        case 4:
            return 'Ապր';
        case 5:
            return 'Մյս';
        case 6:
            return 'Հնս';
        case 7:
            return 'Հլս';
        case 8:
            return 'Օգս';
        case 9:
            return 'Սպտ';
        case 10:
            return 'Հկտ';
        case 11:
            return 'Նյմ';
        case 12:
            return 'Դկտ';
        default:
            throw new Error('Invalid month index');
    }
}