import { select, insert, update, remove, HttpParams } from ".";

export interface ExpenseBase extends HttpParams {
    row_number: string;
    id: string;
    user_id: string;
    date: string;
    sum: string;
    source_id: string;
    source: string;
    target_id: string;
    target: string;
    description: string;
}

export interface ExpenseReportBase extends ExpenseBase {
    full_name: string;
    row_number: string;
    sa_name: string;
    sa_owner: string;
    sa_owner_name: string;
    ta_name: string;
    ta_owner: string;
    ta_owner_name: string;
    time: string;
    type: string;
}

export interface ExpenseByDate extends ExpenseReportBase {
    datetime: string;
    month: string;
    target_level_1: string;
    target_level_2: string;
    target_level_3: string;
    week: string;
    year: string;
}

export interface ExpenseMonth extends ExpenseReportBase { }

export interface ExpenseYear extends ExpenseReportBase { }

export interface Target {
    row_number: string;
    name: string;
}

export async function targets() {
    return select<Target>('vw_expenses', {
        distinct: true,
        where: 'AND LENGTH(target) AND NOT target_id',
        order: 'name ASC',
        group: 'target',
    }, {
            name: 'target',
        });
}

export function expensesByDate(date: string, pageSize: number = 20) {
    return select<ExpenseByDate>('vw_expenses', {
        _query: 'vw_expenses',
        group: '`id`',
        where: `AND \`date\` = '${date}'`,
        _paged: pageSize,
    });
}

export function expensesByMonth(month: Date, pageSize: number = 20) {
    return select<ExpenseMonth>('vw_expenses', {
        _query: 'vw_expenses',
        group: '`target`',
        where: `AND \`month\` = '${month}'`,
        _paged: pageSize,
    }, {
            id: '`id`',
            date: '`date`',
            user_id: '`user_id`',
            source_id: '`source_id`',
            source: '`source`',
            target_id: '`target_id`',
            target: '`target`',
            sum: 'SUM(`sum`)',
            description: '`description`',
            full_name: '`full_name`',
            sa_name: '`sa_name`',
            sa_owner: '`sa_owner`',
            sa_owner_name: '`sa_owner_name`',
            ta_name: '`ta_name`',
            ta_owner: '`ta_owner`',
            ta_owner_name: '`ta_owner_name`',
            type: '`type`',
        });
}

export function expensesByYear(year: number, pageSize: number = 20) {
    return select<ExpenseYear>('vw_expenses', {
        _query: 'vw_expenses',
        group: '`target_level_1`',
        where: `AND \`year\` = '${year}'`,
        _paged: pageSize,
        order: 'sum DESC',
    }, {
            id: '`id`',
            date: '`date`',
            user_id: '`user_id`',
            source_id: '`source_id`',
            source: '`source`',
            target_id: 'null',
            target: '`target_level_1`',
            sum: 'SUM(`sum`)',
            description: '`description`',
            full_name: '`full_name`',
            sa_name: '`sa_name`',
            sa_owner: '`sa_owner`',
            sa_owner_name: '`sa_owner_name`',
            ta_name: '`ta_name`',
            ta_owner: '`ta_owner`',
            ta_owner_name: '`ta_owner_name`',
            type: '`type`',
        });
}

export function addExpense(expense: ExpenseBase) {
    return insert('expenses', expense);
}

export function editExpense(id: number, expense: ExpenseBase) {
    return update('expenses', expense, { id });
}

export function removeExpense(id: number) {
    return remove('expenses', { id });
}