import React, { useState, useEffect } from 'react';
import { Page, TransparentContainer } from '../components/one-ui/atoms/Container';
import { BarChart, ChartData } from '../components/BarChart';
import { expensesByYear, expensesByDate, ExpenseByDate } from '../api/expenses';
import { Grid } from '../components/one-ui/molecules/Grid';
import { Icon } from '../components/one-ui/atoms/Icon';
import { AsNumber } from '../components/helpers/formatters/AsNumber';
import { ReportingDate } from '../ReportingDate';
import { CancelablePromise } from '../CancelablePromise';

export const TodayPage: React.FunctionComponent = () => {
    const [chartYearlyExpenses, setChartYearlyExpenses] = useState<ChartData>([]);
    const [dailyExpenses, setDailyExpenses] = useState<{ date: string, list: ExpenseByDate[] }[]>([]);

    useEffect(() => {
        const expensesByYearPromise = expensesByYear(2019);

        expensesByYearPromise.then(data => setChartYearlyExpenses(
            data.list.map(d => ({ x: d.target, y: parseFloat(d.sum) }))
        ));

        const dates = ReportingDate.range('today', -2).map(d => d.toString());

        const expensesByDatePromise = CancelablePromise.allCancelable(dates.map(date => expensesByDate(date)));

        expensesByDatePromise.then(
            data => setDailyExpenses(data.map(
                ({ list }, index) => ({ date: dates[index], list })
            ))
        );

        return () => {
            expensesByYearPromise.abort();
            expensesByDatePromise.abort();
        };
    }, []);

    return (<Page>
        <TransparentContainer>
            {dailyExpenses.map(({ date, list }) => (
                <TransparentContainer key={date} scroll="horizontal">
                    <h4>{date}</h4>
                    <Grid className="ex-today" data={list} columns={[
                        { name: 'row_number', title: <Icon name="cat" /> },
                        { name: 'target', title: <Icon name="award" /> },
                        { name: 'sum', title: <Icon name="dollar-sign" />, align: 'right', render: row => <AsNumber>{row.sum}</AsNumber> },
                    ]} scroll="none" />
                </TransparentContainer>
            ))}
        </TransparentContainer>
        <TransparentContainer>
            <BarChart label="2019 totals" data={chartYearlyExpenses} limit={5} />
        </TransparentContainer>
    </Page>);
};