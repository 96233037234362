import React from 'react';
import "./Loader.scss";

export interface LoaderProps {
    progress?: number;
}

export const Loader: React.FunctionComponent<LoaderProps> = ({ progress }) => {
    return (
        <div className="loader-container">
            <div className="loader-popup">
                <div className="loader-animation">
                    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" strokeLinecap="round" r="30" strokeWidth="4" stroke="#aaaaaa" strokeDasharray="47.12388980384689 47.12388980384689" transform="rotate(59.0472 50 50)">
                            <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
                        </circle>
                    </svg>
                </div>
                <span className="loader-text">
                    Loading...
                    {progress ? (<span className="loader-progress">
                        {progress}%
                    </span>) : null}
                </span>
            </div>
        </div>
    );
};
