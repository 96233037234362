import React from 'react';
import './Container.scss';

export type ContainerScroll = 'none' | 'horizontal' | 'vertical' | 'both';

export interface ContainerProps {
    className?: string;
    scroll?: ContainerScroll;
}

export const Page: React.FunctionComponent<ContainerProps> = ({ className = '', scroll = 'none', children = null }) => (
    <div className={`one-page ${className} one-container-scroll-${scroll}`}>
        {children}
    </div>
);

export const Container: React.FunctionComponent<ContainerProps> = ({ className = '', scroll = 'none', children = null }) => (
    <div className={`one-container ${className} one-container-scroll-${scroll}`}>
        {children}
    </div>
);

export const SolidContainer: React.FunctionComponent<ContainerProps> = ({ className = '', scroll = 'none', children = null }) => (
    <div className={`one-container one-container-solid ${className} one-container-scroll-${scroll}`}>
        {children}
    </div>
);

export const TransparentContainer: React.FunctionComponent<ContainerProps> = ({ className = '', scroll = 'none', children = null }) => (
    <div className={`one-container one-container-transparent ${className} one-container-scroll-${scroll}`}>
        {children}
    </div>
);