import { Computed, Observable } from './observable';
import { CancelablePromise } from '../CancelablePromise';

export interface DBOptionsBase extends HttpParams { }

export interface SelectOptions extends DBOptionsBase {
    _query?: string;
    _paged?: number;
    distinct?: boolean;
    where?: string;
    order?: string;
    group?: string;
}

export interface ListResult<T> {
    list: T[];
    count: number;

    from: number | false;
    to: number | false;

    page: number | false;
    paged: boolean;
    pages: number | false;

    query: string;
}

export interface InsertResult {
    result: any[];
    query: string;
}

export interface UpdateResult {
    result: any[];
    query: string;
}

export interface DeleteResult {
    result: any[];
    query: string;
}

export interface HttpParams { [name: string]: string | number | boolean | undefined | null }

export const BASE_URL = 'https://family-expenses.projects.mory-soft.com';
export const API_URL = `${BASE_URL}/api/v2/`;
export const session = {
    id: null,
    user: null
};

export const ApiEvents = {

};

const current = new Observable(0);
const total = new Observable(0);
const percentage = new Computed(() => {
    const t = total.value;
    const c = current.value;

    return Math.floor(
        (100 * (t - c) / t) || -1
    );
});

current.subscribe(c => {
    if (c > 0) {
        if (c > total.value) {
            total.value = c;
        }
    } else {
        total.value = 0;
    }
});

export const ApiState = {
    loading: { current, total, percentage }
};

function urlEncode(params: HttpParams) {
    return Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key] || '');
    }).join('&');
}

function objectToOptions(optionName: string, object: HttpParams): HttpParams {
    const options: HttpParams = {};

    for (const { name, value } of Object.keys(object).map(name => ({ name, value: object[name] }))) {
        options[`${optionName}[${name}]`] = value;
    }

    return options;
}

let loginAwaiter: Promise<void | Response> | null = null;

export function login(username: string, password: string): Promise<void | Response> {
    if (loginAwaiter === null) {
        loginAwaiter = fetch(`${API_URL}?action=login&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`)
            .then(res => res.json())
            .then(json => {
                session.id = json.sid;
                session.user = json.data;
            });
    }

    return loginAwaiter;
}

export type ApiAction = 'echo' 
    | 'select' | 'insert' | 'update' | 'delete' /* DB operations */
    | 'me' | 'session' /* Session */;

export function api<T>(action: ApiAction, ...data: HttpParams[]): CancelablePromise<T> {
    return new CancelablePromise(async (resolve, reject, signal) => {
        try {
            ApiState.loading.current.value++;

            console.log('api<T>.begin', ApiState.loading.current.value);

            if (!session.id) {
                await login('Վահան', '0199');
            }

            console.info('api', { session, action, data });

            const res = await fetch(`${API_URL}?${session.id}`, {
                signal,
                method: 'post',
                body: urlEncode(Object.assign({ action }, ...data)),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            ApiState.loading.current.value--;

            console.log('api<T>.end', ApiState.loading.current.value);

            resolve(await res.json());
        } catch (ex) {
            reject(ex);
        }
    });
}

export function select<T>(table: string, options: SelectOptions = {}, fields: HttpParams = {}) {
    const fieldOptions = objectToOptions('fields', fields);

    return api<ListResult<T>>('select', {
        table
    }, options, fieldOptions);
}

export function insert(table: string, fields: HttpParams) {
    const fieldOptions = objectToOptions('item', fields);

    return api<InsertResult>('insert', {
        table
    }, fieldOptions);
}

export function update(table: string, fields: HttpParams, where: HttpParams) {
    const fieldOptions = objectToOptions('item', fields);
    const whereOptions = objectToOptions('where', where);

    return api<UpdateResult>('update', {
        table
    }, fieldOptions, whereOptions);
}

export function remove(table: string, where: HttpParams) {
    const whereOptions = objectToOptions('where', where);

    return api<DeleteResult>('update', {
        table
    }, whereOptions);
}