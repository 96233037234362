
import React, { useEffect, useState } from 'react';
import './Header.scss';
import { Icon } from './Icon';

export interface HeaderProps {
    title: string;
    description?: string;
}

export const Header: React.FunctionComponent<HeaderProps> = ({ title, description }) => {
    const [scrolled, setScrolled] = useState(window.scrollY > 0);

    useEffect(() => {
        const handler = (e: Event) => {
            setScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handler);

        return () => {
            window.removeEventListener('scroll', handler);
        }
    }, []);

    let oneHeaderClassName = 'one-header';

    if (scrolled) {
        oneHeaderClassName += ' one-header-scrolled';
    }

    return (
        <div className={oneHeaderClassName}>
            <div className="one-header-expanded">
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
            <div className="one-header-collapsed">
                <div className="one-header-back">
                    <Icon name="arrow-left" />
                </div>
                <h1>{title}</h1>
                <div className="one-header-actions">

                </div>
            </div>
        </div>
    );
};