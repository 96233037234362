import { select, api } from ".";

export interface User {
    birthday: string;
    default_account: string;
    enabled: string;
    fb_id: string;
    full_name: string;
    id: string;
    password: string;
    role: string;
    row_number: string;
    username: string;
}

export interface Session {
    id: string;
    key: string;
    role: string;
    name: string;
    default_account: string;
}

export async function users() {
    return select<User>('users');
}

export async function me() {
    return api<User>('me');
}

export async function session() {
    return api<Session>('session');
}