import React from 'react';
import './Table.scss';

export type TableCellTextAlign = 'left' | 'right' | 'center';

interface Props {
    className?: string;
}

export const Table: React.FunctionComponent<Props> = ({ className = '', children }) => <table className={`one-table ${className}`}>{children}</table>;

export const TableHead: React.FunctionComponent<Props> = ({ className = '', children }) => <thead className={`one-table-head ${className}`}>{children}</thead>;

export const TableBody: React.FunctionComponent<Props> = ({ className = '', children }) => <tbody className={`one-table-body ${className}`}>{children}</tbody>;

export const TableFoot: React.FunctionComponent<Props> = ({ className = '', children }) => <tfoot className={`one-table-foot ${className}`}>{children}</tfoot>;

export const TableRow: React.FunctionComponent<Props> = ({ className = '', children }) => <tr className={`one-table-row ${className}`}>{children}</tr>;

interface TableCellProps extends Props {
    align?: TableCellTextAlign;
}

export const TableCell: React.FunctionComponent<TableCellProps> = ({ className = '', children, align = 'left' }) => (
    <td className={`one-table-cell one-table-cell-align-${align} ${className}`}>{children}</td>
);

export const TableHeadCell: React.FunctionComponent<Props> = ({ className = '', children }) => <th className={`one-table-head-cell ${className}`}>{children}</th>;