import { useState, useEffect } from 'react';
import { ApiState, ApiAction, HttpParams, api } from '.';

export function useLoadingState() {
    const [isLoading, setIsLoading] = useState<number | null>(null);

    useEffect(() => {
        const unsubscribe = ApiState.loading.percentage.subscribe(isLoading => {
            setIsLoading(isLoading === -1 ? null : isLoading);
        });

        return () => {
            unsubscribe();
        };
    });

    return isLoading;
}

export enum ApiRequestState {
    Pending,
    Success,
    Failure
}

export function useApi<T>(action: ApiAction, ...data: HttpParams[]) {
    const [requestState, setRequestState] = useState(ApiRequestState.Pending);
    const [result, setResult] = useState<T>();

    const promise = api<T>(action, ...data);

    promise.then(data => {
        setRequestState(ApiRequestState.Success);
        setResult(data);
    }).catch(reason => {
        setRequestState(ApiRequestState.Failure);
        // TODO: Don't forgot about error
    });

    const abort = () => promise.abort();

    return { result, requestState, abort };
}